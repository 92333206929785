import moment from "moment"

export const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
        country_flag: 'assets/english-us-logo.png',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
        country_flag: 'assets/arabic-logo.png',
    },
]

export const sorting = [
    {
        name: "Oldest First",
        value: "asc",
    },
    {
        name: "Newest First",
        value: "desc",
    },
]

export const courseContentType = "Course"
export const quizContentType = "Quiz"
export const videoContentType = "Video"

export const quizStatusStarted = "Started"
export const quizStatusInProgress = "InProgress"
export const quizStatusCompleted = "Completed"
export const quizStatusPass = "Pass"
export const quizStatusFail = "Fail"


export const jwtExprieTime = 'app.at_exp'
export const accessToken = 'app.atr'
export const i18next = 'i18next'
export const userData = 'user'


export function copyInviteLink(tenantId,clientID) {

    return `https://auth.skolasti.com/oauth2/register?tenantId=${tenantId}&client_id=${clientID}&nonce=&pendingIdPLinkId=&redirect_uri=https%3A%2F%2Fauthproxy-test.skolasti.com%2Fapp%2Fcallback&response_mode=&response_type=code&scope=openid%20offline_access&state=aHR0cHM6Ly9hcHAtdGVzdC5za29sYXN0aS5jb20%3D%3Ac9aa4b1a8fa29b797c2f16a98fab037372b11e1ab7b544246ffee190%3A&timezone=Asia%2FCalcutta&metaData.device.name=Windows%20Chrome&metaData.device.type=BROWSER&code_challenge=JB1Ia29D1gq5egf4Wt3zu65-ccaeZ8IM7hkNDGoyFck&code_challenge_method=S256&user_code=`
}

export const toTime = (seconds) => {
    var date = new Date(null);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
}

export const sessionStatus = {
    NotStarted: 1,
    InProgress: 2,
    Completed: 3
}

export function sizeConvertor(_size) {
    var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
        i = 0;
    while (_size > 900) { _size /= 1024; i++; }
    var exactSize = (Math.round(_size * 100) / 100) + ' ' + fSExt[i];
    return exactSize
}

export function captureThumbnail(src) {
    const canvas = document.createElement("canvas");
    const video = document.createElement("video");

    // this is important
    video.currentTime = 20;
    video.autoplay = true;
    video.muted = true;
    video.src = src;

    video.onloadeddata = async () => {
        let ctx = canvas.getContext("2d");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return canvas.toDataURL("image/png")
    }
}

export const courseContentTypes = {
    Course: 1,
    Quiz: 2,
    Video: 3,
    SimilarCourse: 4,
    RelatedCourse: 5
}


export const dateDayPrint = (dateTime) => {
    var dateFormat = moment(dateTime).format('YYYY-MM-DD')
    if (moment(dateFormat).isAfter(moment().add(1, 'day').format('YYYY-MM-DD')) || moment(dateFormat).isBefore(moment().format('YYYY-MM-DD'))) {
        return moment(dateTime).format('DD-MM-YYYY')
    }
    else if (moment(dateFormat).isSame(moment().add(1, 'day').format('YYYY-MM-DD'))) {
        return 'Tomorrow'
    }
    else if (moment(dateFormat).isSame(moment().format('YYYY-MM-DD'))) {
        return 'Today'
    }
}

export const checkSameDay = (startTime, endTime) => {
    var d1 = new Date(startTime).getTime()
    var d2 = new Date(endTime).getTime()
    var curr = new Date()
    var currUTC = Date.UTC(curr.getFullYear(), curr.getMonth(), curr.getDate(), (curr.getHours() + 25) % 12 || 12, curr.getMinutes(), curr.getSeconds())
    if (currUTC < d1) {
        return {
            timer: true,
            started: false,
            completed: false
        }
    }
    else if (currUTC >= d1 && currUTC < d2) {
        return {
            timer: false,
            started: true,
            completed: false
        }
    }
    else if (currUTC >= d2) {
        return {
            timer: false,
            started: false,
            completed: true
        }
    }
}


export const timerSetup = (dateTime) => {
    var curr = new Date().getTime()
    var then = new Date(dateTime).getTime()
    var tsec = Math.round((then - curr) / 1000);
    var sec = tsec % 60
    var tmin = (tsec - sec) / 60;
    var min = tmin % 60
    var th = (tmin - min) / 60;
    var h = th % 24
    var d = (th - h) / 24;

    return `${d} ${h} ${min} ${sec}`
}

export const LocalTimeFormat = (dateTime) => {
    return new Date(dateTime + '+00:00').toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
}


export const liveCourseProviders = {
    Zoom: 'ZOOM',
    Teams: 'TEAMS',
    VideoSdk: 'VIDEOSDK'
}


export function sessionPayload(mail, provider, body) {
    return {
        Email: mail,
        Provider: provider,
        Body: body
    }
}

export const webinarJoinLinks = {
    HostLink: 'webinar/host/',
    ParticipantLink: 'webinar/viewer/',
}


export default copyInviteLink