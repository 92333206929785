import axios from "axios";
import {
  getData,
  postData,
  putData,
  deleteData
} from "../../../axios/axiosbase";
import store from "../../../store/configureStore";
import Cookies from "js-cookie";
import { hlsVideoExtension, uploadedVideoStateInProgress } from "../Common/Constants";
import { accessToken } from "../../../components/Common/Constants";

const postTutorialVideo = payload => {
  const videos = [];
  payload.file.forEach(file => {
    // get file name without extension
    const index = file.name.lastIndexOf(".") + 1;
    const fileName = file.name.substring(0, index - 1);
    // console.log("file-name", fileName);
    videos.push({
      FileName: fileName,
      state: "Uploading",
      // IsPublic: true,
      IsSharingAllowed: true
    });
  })

  const body = JSON.stringify({
    // Videos: [
    //   {
    //     FileName: fileName,
    //     state: "Uploading",
    //     // IsPublic: true,
    //     IsSharingAllowed: true
    //   }
    // ]
    Videos: videos
  });
  
  var token = Cookies.get(accessToken);
  const config = {
    onUploadProgress: function (progressEvent) {
      const percentCompleted = Math.round(
        progressEvent.loaded * 100 / progressEvent.total
      );
      sessionStorage.setItem("percentage", percentCompleted);
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`
    },
    withCredentials: true
  };

  const url =
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/VideoAdmin/create`;

  return axios.post(url, body, config).catch(err => console.log(err));
};

const postTutorialCourse = payload => {
  // get file name without extension
  // const index = payload.name.lastIndexOf(".") + 1;
  // const fileName = payload.name.substring(0, index - 1);
  // console.log("file-name", fileName);

  const body = JSON.stringify({
    videos: payload.map(a => {
      return {
        FileId: (a.FileId && a.FileId.length > 0) ? a.FileId : null,
        FileName: a.FileName,
        State: a.State,
        IsVrEnabled: a.IsVrEnabled
      }
    })
  })

  var token = Cookies.get(accessToken);
  const config = {
    onUploadProgress: function (progressEvent) {
      const percentCompleted = Math.round(
        progressEvent.loaded * 100 / progressEvent.total
      );
      sessionStorage.setItem("percentage", percentCompleted);
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`
    },
    withCredentials: true
  };

  const url =
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/VideoAdmin/create`;

  return axios.post(url, body, config).catch(err => console.log(err));
};

const uploadVideoForHls = (formData) => {
  var token = Cookies.get(accessToken);
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`
    },
    withCredentials: true
  };
  const url = `${process.env.REACT_APP_HLS_URL}/api/v1/hls/upload-video`
  return axios.post(url, formData, config);
}

const postTutorialDocument = payload => {
  console.log(payload);

  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/VideoAdmin/createdocument`,
    payload
  ).catch(err => console.log(err));
};

const updateTutorial = payload => {

  return putData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/VideoAdmin/update`,
    payload
  ).catch(err => console.log("tutorial Update =>", err));
};

const updateTutorialData = payload => {
  return putData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/VideoAdmin/update`,
    payload
  ).catch(err => console.log("tutorial Update =>", err));
};

const updateVideoData = payload => {
  return putData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/VideoAdmin/updatevideodata`,
    payload
  ).catch(err => console.log("tutorial Update =>", err));
};

const updateCourseData = payload => {
  return putData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/CourseAdmin/updatecourse`,
    payload
  ).catch(err => console.log("course Update =>", err));
};

const updateDocument = payload => {
  return putData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/VideoAdmin/updatedocument`,
    payload
  );
};

const fetchCategories = () => {
  return getData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/Category/getallcategoryies`
  );
};

const fetchPlaylistCategories = () => {
  return getData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/Category/getallplaylistcategoryies`
  );
};

const fetchAdminVideo = payload => {
  const pagination = {
    PageNumber: payload.pageNumber,
    PageSize: "20"
  };

  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/VideoAdmin/alldetails`,
    pagination
  );
};

const getAdminVideoDetails = id => {
  return getData(process.env.REACT_APP_ADMIN_BASE_URL + `/VideoAdmin/details/${id}`);
}

const fetchAdminVideoFilter = payload => {
  const pagination = {
    PageNumber: payload.pageNumber,
    PageSize: "20",
    FilterField: payload.filterField,
    FilterText: payload.filterText,
    SortField: payload.sortField,
    SortType: payload.sortType
  };
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/VideoAdmin/alldetails`,
    pagination
  );
};

const fetchAdminVideoSort = payload => {
  const pagination = {
    PageNumber: payload.pageNumber,
    PageSize: "20",
    SortField: payload.sortField,
    SortType: payload.sortType
  };
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/VideoAdmin/alldetails`,
    pagination
  );
};

const fetchAdminCourse = payload => {
  const pagination = {
    PageNumber: payload,
    PageSize: "20"
  };
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/CourseAdmin/getalladmincourses`,
    pagination
  );
};

const fetchAdminCourseFilter = payload => {
  const pagination = {
    PageNumber: payload.pageNumber,
    PageSize: "20",
    FilterField: payload.filterField,
    FilterText: payload.filterText,
    SortField: payload.sortField,
    SortType: payload.sortType
  };
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/CourseAdmin/getalladmincourses`,
    pagination
  );
};

const shareCourses = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/CourseAdmin/createsharecourse?playlistId=${payload.playlistId}&sharedBy=${payload.sharedBy}&sharedWith=${payload.sharedWith}`
  );
};

const shareVideo = payload => {
  const body = {
    VideoId: payload.videoId,
    SharedBy: payload.sharedBy,
    SharedWith: payload.sharedWith
  };

  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/VideoAdmin/sharevideo`,
    body
  );
};

const fetchShareVideoById = payload => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().user.accessToken}`
    }
  };

  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/VideoAdmin/sharevideo/${payload}`,
    config
  ).catch(err => console.log(err));
};

const fetchShareCourseById = payload => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().user.accessToken}`
    }
  };

  return getData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/CourseAdmin/getsharecoursebyid?userId=${payload.userId.trim()}&id=${payload.courseId}`,
    config
  ).catch(err => console.log(err));
};

const fetchRoles = payload => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().user.accessToken}`
    }
  };

  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/UserManagement/getuserroles`,
    payload,
    config
  ).catch(err => console.log(err));
};

const updateUserRole = payload => {
  const body = {
    UserId: payload.UserId,
    RoleId: payload.RoleId
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().user.accessToken}`
    }
  };

  return putData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/UserManagement/updateuserroleinusers`,
    body,
    config
  ).catch(err => console.log(err));
};

const createPlaylist = payload => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().user.accessToken}`
    }
  };

  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/CourseAdmin/createcourse`,
    payload,
    config
  ).catch(err => console.log(err));
};

const addVideoToPlaylist = payload => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().user.accessToken}`
    }
  };

  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL + 
    `/CourseAdmin/addvideotoplaylist`,
    payload,
    config
  ).catch(err => console.log(err));
};

const createGroup = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/GroupManagement/creategroup`,
    payload
  ).catch(err => console.log(err));
};

const addUserToGroup = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/GroupManagement/addusertogroup?groupId=${payload.GroupId}&userId=${payload.UserId}`
  ).catch(err => console.log(err));
};

const removeUserFromGroup = payload => {
  return putData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/GroupManagement/removeuserfromgroup?groupId=${payload.GroupId}&userId=${payload.UserId}`
  ).catch(err => console.log(err));
};

const addCourseToGroup = payload => {
  return putData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/GroupManagement/sharecoursetogroup?groupId=${payload.GroupId}&playlistId=${payload.PlaylistId}`
  ).catch(err => console.log(err));
};

const removeCourseFromGroup = payload => {
  return putData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/GroupManagement/unsharecoursefromgroup?groupId=${payload.GroupId}&playlistId=${payload.PlaylistId}`
  ).catch(err => console.log(err));
};

const deleteGroup = payload => {
  return deleteData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/GroupManagement/deletegroup?groupId=${payload}`
  ).catch(err => console.log(err));
};

const deleteVideo = payload => {
  const config = {
    headers: {
      Authorization: `Bearer ${store.getState().user.accessToken}`
    }
  };
  return deleteData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/VideoAdmin/delete?id=${payload}`,
    config
  ).catch(err => console.log(err));
};

const deleteCourse = payload => {
  const config = {
    headers: {
      Authorization: `Bearer ${store.getState().user.accessToken}`
    }
  };
  return deleteData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/CourseAdmin/deletecourse?playListId=${payload}`,
    config
  ).catch(err => console.log(err));
};

const deleteDocument = payload => {
  const config = {
    headers: {
      Authorization: `Bearer ${store.getState().user.accessToken}`
    }
  };
  return deleteData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/VideoAdmin/deletedocument?id=${payload}`,
    config
  ).catch(err => console.log(err));
}

const updateUserData = payload => {
  return putData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/UserManagement/updateuserdata`,
    payload
  );
};

const getVideoHistoryByUserIdAdmin = payload => {
  return getData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/VideoAdmin/getalluservideoshistories?userId=${payload}`
  );
};

const getVideoHistoryByVideoIdAdmin = payload => {
  return getData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/VideoAdmin/GetAllUserVideoHistoryByVideoId?videoId=${payload}`
  );
};

const fetchSavedCourses = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/CourseAdmin/getsavedcourse?userId=${payload.Id}`,
    payload.body
  );
};

const getSharedWithMeCourses = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/CourseAdmin/getsharedwithmecourse?userId=${payload.Id}`,
    payload.body
  );
};

const getCertificateById = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/Certificate/getcertificatebyid?userId=${payload.Id}`,
    payload.body
  );
};

const getCourseUserDetails = payload => {
  return getData(
    process.env.REACT_APP_BASE_URL +
    `CourseAdmin/getcourseuserdetails?playlistId=${payload.Id}`
  );
};

const getOrganisationDetails = payload => {
  return getData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/OrganizationProfileManagement/getorganisationdata`
  );
};

const createQuiz = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/QuizAndAssessmentAdmin/createquiz`,
    payload
  );
};

const createQuizQuestions = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/createassessment`,
    payload
  );
};

const updateQuiz = payload => {
  return putData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/QuizAndAssessmentAdmin/updatequiz`,
    payload
  );
};

const updateQuizQuestions = payload => {
  return putData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/updatequestion`,
    payload
  );
};

const addQuestionstoQuiz = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/addquizandquestion`,
    payload
  );
};

const getAllQuizes = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/getallquizes`,
    payload
  );
};

const getAllQuestions = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/getallquestions`,
    payload
  );
};

const getCourseVideo = payload => {
  return getData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/CourseAdmin/getvideolist?playlistId=${payload}`
  );
};

const getVideoList = payload => {
  return getData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/searchallquiz?title=${payload}`
  );
};

const assignQuiztoPlaylist = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/assignquiztoplaylist?quizId=${payload.quizId}&playlistId=${payload.playlistId}&indexSequence=${payload.indexSequence}`
  );
};

const reOrderCourse = payload => {
  return putData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/CourseAdmin/reordercourse`,
    payload
  );
};

const removeQuiz = payload => {
  return deleteData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/deletequiz?quizId=${payload}`
  );
};

const removeQuestions = payload => {
  return deleteData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/deletequestion?questionId=${payload}`
  );
};

const removeQuizFromCourse = payload => {
  return deleteData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/removequizfromcourse?quizId=${payload}`
  );
};
// const getTenant = (payload) => {
//   return axios.get(`http://localhost:9000/app/tenant`, {headers: {'tenantId': payload}})
// }

const getTenant = payload => {
  return getData(
    process.env.REACT_APP_AUTH_TENANT_URI +
    `/gettenantsbyname?tenantName=${payload}`
  );
};

const getTenantById = payload => {
  console.log(payload);
  return getData(
    process.env.REACT_APP_AUTH_TENANT_URI +
    `/gettenantsbyid?tenantId=${payload}`
  );
};

const removeQuizFromQuestion = payload => {
  return deleteData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/removequizfromquestion?quizId=${payload}`
  );
};

const getQuizDetails = payload => {
  return getData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/getquizdetails?quizId=${payload}`
  );
};

const removeQuizFromPlaylist = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/removequizfromplaylist?quizId=${payload.quizId}&playlistId=${payload.playlistId}`
  );
};

const removeVideoFromPlaylist = payload => {
  return deleteData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/CourseAdmin/removevideofromplaylist?playlistId=${payload.playlistId}&videoId=${payload.videoId}`
  );
};

const reArrangeCourse = payload => {
  return putData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/CourseAdmin/rearrangeCourse?playListId=${payload}`
  );
};

const getCourseEnrollment = () => {
  return getData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/CourseAdmin/getcourseenrollment`
  );
};

const getUserEnrollment = payload => {
  if (payload === undefined) {
    return getData(
      process.env.REACT_APP_ADMIN_BASE_URL + `/CourseAdmin/getuserenrollment`
    );
  } else {
    return getData(
      process.env.REACT_APP_ADMIN_BASE_URL +
      `/CourseAdmin/getuserenrollment?playlistId=${payload}`
    );
  }
};

const getCourseVsCertificate = payload => {
  return getData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/CourseAdmin/getcouservscertificate`
  );
};

const getUserEngagement = payload => {
  if (payload === undefined) {
    return getData(
      process.env.REACT_APP_ADMIN_BASE_URL + `/UserManagement/getuserengagement`
    );
  } else {
    return getData(
      process.env.REACT_APP_ADMIN_BASE_URL +
      `/UserManagement/getuserengagement?userId=${payload}`
    );
  }
};

const getUserActiveCount = () => {
  return getData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/UserManagement/getuseractivecount`
  );
};

const getAllGroup = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/GroupManagement/getgroups`,
    payload
  );
};

const getAllGroupUsers = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/GroupManagement/getallgroupusers?groupId=${payload.groupId}`,
    payload
  );
};

const getAllGroupCourses = payload => {
  console.log("groups ", payload);
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/GroupManagement/getallgroupsharecourse?groupId=${payload.groupId}`,
    payload
  );
};

const getGroupById = payload => {
  return getData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/GroupManagement/getgroupbyid?groupId=${payload}`
  );
};

const getAdminSettings = () => {
  return getData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/VisibilityTenantSettings/gettenantsettings`
  );
};

const updateAdminSettings = payload => {
  return putData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/VisibilityTenantSettings/updatetenantsettings`,
    payload
  );
};

const getQuestionLevels = payload => {
  return getData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/getquizlevels`
  );
};

const assignQuestionsToQuiz = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/assignquestiontoquiz?quizId=${payload.quizId}&score=${payload.score}`,
    payload.questionId
  );
};

const updateQuestionsToQuiz = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/updatequestiontoquiz?questionId=${payload.questionId}&quizId=${payload.quizId}&score=${payload.score}`
  );
};

const createQuestions = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/createquestion`,
    payload
  );
};

const updateQuestions = payload => {
  return putData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/updatequestion`,
    payload
  );
};

const fetchQuizWithFilter = payload => {
  return getData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/getquizwithdifficultyfilter?difficultyLevel=${payload.difficultyId}&quizTitle=${payload.title}`
  );
};

const removeQuestionfromquiz = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/removequestionfromquiz?quizId=${payload.quizId}`,
    payload.questionId
  );
};

const removeQuestionfromAllQuiz = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/removequestionfromallquiz?questionId=${payload}`
  );
};

const getallQuizQuestions = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/getallquizquestions?quizId=${payload.Id}`,
    payload.Body
  );
};

const deleteQuestions = payload => {
  return deleteData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/QuizAndAssessmentAdmin/deletequestion?questionId=${payload}`
  );
};

const createLiveCourse = payload => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().user.accessToken}`
    }
  };

  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/CourseAdmin/createcourse`,
    payload,
    config
  ).catch(err => console.log(err));
};

const createSessions = payload => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().user.accessToken}`
    }
  };

  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/SessionsAdmin/createsessions`,
    payload,
    config
  ).catch(err => console.log(err));
};

const getSessionDetail = payload => {
  return getData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/SessionsAdmin/getadminsessionplaylistbyid?playlistId=${payload}`
  );
}

const updateSessions = payload => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().user.accessToken}`
    }
  };

  return putData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/SessionsAdmin/updatesession`,
    payload,
    config
  ).catch(err => console.log(err));
};

const createSessionDocument = payload => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().user.accessToken}`
    }
  };

  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/SessionsAdmin/createwebinardocument`,
    payload,
    config
  ).catch(err => console.log(err));
};

const updateSessionDocument = payload => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().user.accessToken}`
    }
  };

  return putData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/SessionsAdmin/updatewebinardocument`,
    payload,
    config
  ).catch(err => console.log(err));
};

const deleteWebnarDocument = payload => {
  const config = {
    headers: {
      Authorization: `Bearer ${store.getState().user.accessToken}`
    }
  };
  return deleteData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/SessionsAdmin/deleteWebinardocument?id=${payload}`,
    config
  ).catch(err => console.log(err));
}

const revomeSessions = payload => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().user.accessToken}`
    }
  };

  return deleteData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/SessionsAdmin/deletesession?sessionId=${payload}`,
    config
  ).catch(err => console.log(err));
};

const getSessionDocument = payload => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().user.accessToken}`
    }
  };

  return getData(
    process.env.REACT_APP_ADMIN_BASE_URL +
    `/SessionsAdmin/getwebinardocumentbyid?sessionId=${payload.sessionId}`,
    config
  ).catch(err => console.log(err));
};

const inviteUser = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/UserManagement/inviteuser`,
    payload
  ).catch(err => console.log(err));
};

const fetchFusionAuthRoles = payload => {
  return postData(
    process.env.REACT_APP_ADMIN_BASE_URL + `/UserManagement/getfusionauthuserroles`,
    payload
  ).catch(err => console.log(err));
};

export default {
  getUserActiveCount: getUserActiveCount,
  getUserEngagement: getUserEngagement,
  getCourseVsCertificate: getCourseVsCertificate,
  getCourseEnrollment: getCourseEnrollment,
  getUserEnrollment: getUserEnrollment,
  reArrangeCourse: reArrangeCourse,
  removeQuizFromPlaylist: removeQuizFromPlaylist,
  removeVideoFromPlaylist: removeVideoFromPlaylist,
  postTutorialVideo: postTutorialVideo,
  postTutorialCourse: postTutorialCourse,
  postTutorialDocument: postTutorialDocument,
  updateTutorial: updateTutorial,
  updateDocument: updateDocument,
  fetchAdminVideo: fetchAdminVideo,
  fetchAdminCourse: fetchAdminCourse,
  fetchCategories: fetchCategories,
  shareCourses: shareCourses,
  shareVideo: shareVideo,
  fetchShareVideoById: fetchShareVideoById,
  fetchShareCourseById: fetchShareCourseById,
  fetchRoles: fetchRoles,
  updateUserRole: updateUserRole,
  fetchPlaylistCategories: fetchPlaylistCategories,
  createPlaylist: createPlaylist,
  addVideoToPlaylist: addVideoToPlaylist,
  createGroup: createGroup,
  addUserToGroup: addUserToGroup,
  removeUserFromGroup: removeUserFromGroup,
  addCourseToGroup: addCourseToGroup,
  removeCourseFromGroup: removeCourseFromGroup,
  deleteGroup: deleteGroup,
  deleteVideo: deleteVideo,
  deleteCourse: deleteCourse,
  deleteQuestions: deleteQuestions,
  fetchAdminVideoFilter: fetchAdminVideoFilter,
  fetchAdminCourseFilter: fetchAdminCourseFilter,
  fetchAdminVideoSort: fetchAdminVideoSort,
  updateUserData: updateUserData,
  updateTutorialData: updateTutorialData,
  updateCourseData: updateCourseData,
  getVideoHistoryByUserIdAdmin: getVideoHistoryByUserIdAdmin,
  getVideoHistoryByVideoIdAdmin: getVideoHistoryByVideoIdAdmin,
  fetchSavedCourses: fetchSavedCourses,
  getSharedWithMeCourses: getSharedWithMeCourses,
  getCertificateById: getCertificateById,
  getCourseUserDetails: getCourseUserDetails,
  getOrganisationDetails: getOrganisationDetails,
  createQuiz: createQuiz,
  createQuizQuestions: createQuizQuestions,
  addQuestionstoQuiz: addQuestionstoQuiz,
  getAllQuizes: getAllQuizes,
  getCourseVideo: getCourseVideo,
  getVideoList: getVideoList,
  assignQuiztoPlaylist: assignQuiztoPlaylist,
  reOrderCourse: reOrderCourse,
  removeQuiz: removeQuiz,
  removeQuizFromCourse: removeQuizFromCourse,
  removeQuizFromQuestion: removeQuizFromQuestion,
  getQuizDetails: getQuizDetails,
  updateQuiz: updateQuiz,
  updateQuizQuestions: updateQuizQuestions,
  updateVideoData: updateVideoData,
  getAllGroup: getAllGroup,
  getGroupById: getGroupById,
  getAllGroupUsers: getAllGroupUsers,
  getAllGroupCourses: getAllGroupCourses,

  getTenant: getTenant,
  getTenantById: getTenantById,

  getAdminSettings: getAdminSettings,
  updateAdminSettings: updateAdminSettings,
  getQuestionLevels: getQuestionLevels,
  assignQuestionsToQuiz: assignQuestionsToQuiz,
  createQuestions: createQuestions,
  updateQuestions: updateQuestions,
  fetchQuizWithFilter: fetchQuizWithFilter,
  updateQuestionsToQuiz: updateQuestionsToQuiz,
  getAllQuestions: getAllQuestions,
  removeQuestions: removeQuestions,
  removeQuestionfromquiz: removeQuestionfromquiz,
  removeQuestionfromAllQuiz: removeQuestionfromAllQuiz,
  getallQuizQuestions: getallQuizQuestions,

  createLiveCourse: createLiveCourse,
  createSessions: createSessions,
  updateSessions: updateSessions,
  createSessionDocument: createSessionDocument,
  updateSessionDocument: updateSessionDocument,
  deleteWebnarDocument: deleteWebnarDocument,
  revomeSessions: revomeSessions,
  getSessionDocument: getSessionDocument,
  inviteUser: inviteUser,
  fetchFusionAuthRoles: fetchFusionAuthRoles,
  getAdminVideoDetails: getAdminVideoDetails,
  deleteDocument: deleteDocument,
  uploadVideoForHls: uploadVideoForHls,
  getSessionDetail: getSessionDetail
};
