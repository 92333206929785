import React, { Component } from "react";
import { connect } from "react-redux";
import PlaceholderLoader from "../../components/Common/PlaceholderLoader";
import { bindActionCreators } from "redux";
import BrowseHeader from "./BrowseHeader";
import BrowseBody from "./BrowseBody";
import { fetchSavedTutorials, fetchSavedVideosLoadMore } from "../../store/actions/tutorials";
import { fetchSavedCourses, fetchSavedCourseLoadMore } from "../../store/actions/courses";
import API from "../../utils/API";
import LoadingScreen from "../../components/Common/LoadingScreen";
import { BlueBarTranslated } from "../../components/Common/BlueBar";
import EmptyEnrollScreen from "../Admin/Common/EmptyEnrollScreen";

export class BrowseBookmarked extends Component {
  constructor(props) {
    super(props);
    this.timeout = 0;
    this.state = {
      videoHasMore: false,
      courseHasMore: true,
      filterVideoData: {
        Count: 0,
        Videos: []
      },
      filterCourseData: {
        Count: 0,
        Playlist: []
      },
      filterField: "",
      sortField: "",
      search: "",
    }
  }

  async componentDidMount() {
    const { fetchSavedTutorials, fetchSavedCourses } = this.props;
    console.log(this.props)
    this.props.savedVideoPageNumber === 2 &&
      fetchSavedTutorials({
        Id: this.props.profile.id,
        body: {
          PageNumber: this.props.savedVideoPageNumber - 1
        }
      }
      );
    this.props.savedCoursePageNumber === 2 &&
      fetchSavedCourses({
        Id: this.props.profile.id,
        body: {
          PageNumber: this.props.savedCoursePageNumber - 1
        }
      });
  }

  filterData = (evt) => {
    this.setState({ search: evt.trim() })
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      API.fetchSavedCourses({
        Id: this.props.profile.id,
        body: {
          FilterField: 'Title',
          FilterText: evt
        }
      }).then(res => res.status === 200 ? this.setState({
        filterCourseData: {
          Count: res.data.count,
          Playlist: res.data.Playlist
        }
      }) : this.setState({
        filterCourseData: {
          Count: 0,
          Playlist: []
        }
      }))
      API.fetchSavedTutorials({
        Id: this.props.profile.id,
        body: {
          FilterField: 'Title',
          FilterText: evt
        }
      }).then(res => res.status === 200 ? this.setState({
        filterVideoData: {
          Count: res.data.count,
          Videos: res.data.Videos
        }
      }) : this.setState({
        filterVideoData: {
          Count: 0,
          Videos: []
        }
      }))
    }, 500);
  }

  changeSort = (evt) => {
    this.setState({ search: evt.trim() })
    this.setState({
      sortField: evt
    })
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      // console.log(this.state.search)
      API.fetchSavedCourses({
        Id: this.props.profile.id,
        body: {
          SortField: 'CreatedDate',
          SortType: evt,
          FilterField: 'CategoryName',
          FilterText: this.state.filterField,
        }
      }).then(res => res.status === 200 ? this.setState({
        filterCourseData: {
          Count: res.data.count,
          Playlist: res.data.Playlist
        }
      }) : this.setState({
        filterCourseData: {
          Count: 0,
          Playlist: []
        }
      }))
      API.fetchSavedTutorials({
        Id: this.props.profile.id,
        body: {
          SortField: 'CreatedDate',
          SortType: evt,
          FilterField: 'CategoryName',
          FilterText: this.state.filterField,
        }
      }).then(res => res.status === 200 ? this.setState({
        filterVideoData: {
          Count: res.data.count,
          Videos: res.data.Videos
        }
      }) : this.setState({
        filterVideoData: {
          Count: 0,
          Videos: []
        }
      }))
    }, 500);
  }

  change = (evt) => {
    this.setState({ search: evt.trim() })
    this.setState({
      filterField: evt
    })
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      // console.log(this.state.search)
      API.fetchSavedCourses({
        Id: this.props.profile.id,
        body: {
          FilterField: 'CategoryName',
          FilterText: evt,
          SortField: "CreatedDate",
          SortType: this.state.sortField,
        }
      }).then(res => res.status === 200 ? this.setState({
        filterCourseData: {
          Count: res.data.count,
          Playlist: res.data.Playlist
        }
      }) : this.setState({
        filterCourseData: {
          Count: 0,
          Playlist: []
        }
      }))
      API.fetchSavedTutorials({
        Id: this.props.profile.id,
        body: {
          FilterField: 'CategoryName',
          FilterText: evt,
          SortField: "CreatedDate",
          SortType: this.state.sortField,
        }
      }).then(res => res.status === 200 ? this.setState({
        filterVideoData: {
          Count: res.data.count,
          Videos: res.data.Videos
        }
      }) : this.setState({
        filterVideoData: {
          Count: 0,
          Videos: []
        }
      }))
    }, 500);
  }

  handleBrowseVideoScroll = (event) => {
    const { fetchSavedVideosLoadMore, videoLazyloader, savedVideoPageNumber } = this.props;
    const payload = {
      Id: this.props.profile.id,
      body: {
        PageNumber: savedVideoPageNumber
      }
    };
    if (videoLazyloader === false) {
      this.setState({
        videoHasMore: false
      })
    }
    setTimeout(() => {
      fetchSavedVideosLoadMore(payload);
    }, 1000);
  }

  handleBrowseCourseScroll = (event) => {
    const { fetchSavedCourseLoadMore, courseLazyLoader, savedCoursePageNumber } = this.props;
    const payload = {
      Id: this.props.profile.id,
      body: {
        PageNumber: savedCoursePageNumber
      }
    };
    console.log(courseLazyLoader)
    if (courseLazyLoader === false) {
      this.setState({
        courseHasMore: false
      })
    }
    setTimeout(() => {
      fetchSavedCourseLoadMore(payload);
    }, 1000);
  }



  render() {
    const { savedTutorials, loaded, savedCourses, videoLazyloader, courseLazyLoader } = this.props;
    return loaded ? (
      <div>
        <BlueBarTranslated props={this.props} />
        <BrowseHeader props={this.props} browseType={"SavedByMe"} filterData={(evt) => this.filterData(evt)} changeSort={(evt) => this.changeSort(evt)} change={(evt) => this.change(evt)}></BrowseHeader>

        {!savedTutorials.Count > 0 && !savedCourses.Count > 0 ?
          <>
            {/* <BrowseHeader props={this.props} browseType={"SavedByMe"} filterData={(evt) => this.filterData(evt)} changeSort={(evt) => this.changeSort(evt)} change={(evt) => this.change(evt)}></BrowseHeader> */}
            <EmptyEnrollScreen />
          </>
          :
          <>
            {/* <BrowseHeader props={this.props} browseType={"SavedByMe"} filterData={(evt) => this.filterData(evt)} changeSort={(evt) => this.changeSort(evt)} change={(evt) => this.change(evt)}></BrowseHeader> */}
            <BrowseBody
              props={this.props}
              tutorials={savedTutorials}
              courses={savedCourses}
              loaded={loaded}
              searchValue={this.state.search}
              filterDataVideo={this.state.filterVideoData}
              filterCourseData={this.state.filterCourseData}
              handleBrowseVideoScroll={this.handleBrowseVideoScroll}
              handleBrowseCourseScroll={this.handleBrowseCourseScroll}
              videoLazyloader={videoLazyloader}
              courseLazyLoader={courseLazyLoader}
              videoHasMore={this.state.videoHasMore}
              courseHasMore={this.state.courseHasMore}
              type="save"
            >
            </BrowseBody>
          </>
        }
      </div>
    ) : (
      <LoadingScreen></LoadingScreen>
    );
  }
}

const mapStateToProps = (state, props) => ({
  savedTutorials: state.tutorials.savedTutorials,
  savedCourses: state.courses.savedCourses,
  loaded: state.tutorials.loaded,
  videoLazyloader: state.tutorials.savedVideoLazyLoader,
  courseLazyLoader: state.courses.savedCourseLazyLoader,
  profile: state.profileData,
  savedVideoPageNumber: state.tutorials.savedVideoPageNumber,
  savedCoursePageNumber: state.courses.savedCoursePageNumber,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchSavedTutorials: fetchSavedTutorials,
      fetchSavedCourses: fetchSavedCourses,
      fetchSavedVideosLoadMore: fetchSavedVideosLoadMore,
      fetchSavedCourseLoadMore: fetchSavedCourseLoadMore,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BrowseBookmarked);
