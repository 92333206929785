import { useEffect } from 'react'

const InValidatedFilesError = ({ inValidatedFiles, handleInValidatedFiles }) => {

    return (
        <>
            {inValidatedFiles.length ?
                <div style={_container_}>
                    <div style={_close_button_} onClick={() => handleInValidatedFiles([])}>X</div>
                    <p style={{fontSize: '1.2rem'}}>The following files resolution is more than 1080 pixels.</p>
                    <ul>
                        {inValidatedFiles.map((d, idx) => <li key={idx}>{d.toString()}</li>)}
                    </ul>
                </div>
            : ""}
        </>
    )
}

export default InValidatedFilesError

const _container_ = {
    backgroundColor: '#fadbd8',
    border: '1px solid #ec7063',
    color: '#ec7063',
    padding: '1.5rem',
    position: 'relative',
    marginBottom: '2rem'
}

const _close_button_ = {
    color: '#ec7063',
    fontWeight: '900',
    position: 'absolute',
    top: '.3rem',
    right: '.3rem',
    cursor: 'pointer',
    fontSize: '1.5rem'
}