import React from 'react';
import { FusionAuthProvider, RedirectFail, RedirectSuccess, useFusionAuth } from '@fusionauth/react-sdk';
import { useNavigate, useParams } from 'react-router-dom';
import { config } from './utils/fusionAuthConfig';
import API from './modules/Admin/utils/API';
import Cookies from 'js-cookie';
import Login from './components/AuthComponents/Login';
import { fetchTenantDetailsAction } from './modules/Admin/store/actions/admin';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import PageNotFound from './components/Common/PageNotFound';

const FusionAuthProviderWithRedirectHandling = ({ children, tenant, fetchTenant }) => {
    const [clientId, setClientId] = React.useState('')
    const [tenantId, setTenantId] = React.useState('')
    const { isAuthenticated, user, login } = useFusionAuth();
    const ten = window.location.pathname.split('/')[1]
    // const basename = tenant.tenant.tenantRouteName === undefined ? ten : tenant.tenant.tenantRouteName

    // Custom handling of success to navigate to the appropriate location via React Router
    const handleRedirectSuccess = () => {
        sessionStorage.setItem('tenant', tenant.tenant.tenantRouteName)
        navigate('/');
    };

    // Custom handle failure
    const handleRedirectFail = error => {
        window.alert('Something went wrong');
    };

    React.useEffect(() => {
        if (window.location.pathname.split('/')[1] !== undefined && window.location.pathname.split('/')[1] !== 'default' && window.location.pathname.split('/')[1] !== '') {
            setTenantId(window.location.pathname.split('/')[1])
        }
    }, [])

    React.useEffect(() => {
        if (tenant?.tenant?.clientId && tenant?.tenant?.tenantId) {
            sessionStorage.setItem('clientId', tenant.tenant.clientId);
            sessionStorage.setItem('tenantId', tenant.tenant.tenantId);
        }
    }, [tenant]);

    React.useEffect(() => {
        if (tenantId != undefined && tenantId.length > 0) {
            fetchTenant(tenantId)
            // setClientId(tenant.tenant.subscriptionId)

            // API.getTenant(tenantId).then(res => res.status === 200 ? (
            //     setClientId(res.data.subscriptionId)
            // ) :
            //     setClientId('')
            // )
        }
    }, [tenantId])

    React.useEffect(() => {
        if(tenant.error != true){

            if (tenant.tenant.clientId != '') {
                setClientId(tenant.tenant.clientId)
                console.log('tenanr', tenant)
    
            }
            else {
                setClientId(process.env.REACT_APP_AUTH_CLIENTID)
            }
        }
        
    }, [tenant])

    return (
        (tenant.loaded === true &&
            window.location.pathname.split('/')[1] !== undefined &&
            window.location.pathname.split('/')[1] !== 'default' &&
            window.location.pathname.split('/')[1] !== '' &&
            tenant.error !== true) ? (
                <FusionAuthProvider
                    clientID={clientId}
                    serverUrl={process.env.REACT_APP_FUSION_AUTH_SERVER}
                    redirectUri={process.env.REACT_APP_AUTH_REDIRECT_URI}
                    onRedirectSuccess={handleRedirectSuccess}
                    // logoutPath={process.env.REACT_APP_AUTH_REDIRECT_URI + "/" + basename + "/login?redirectUri=/"}
                >
                    {children}
                </FusionAuthProvider>
            ) : tenant.loaded === true ? (
                <PageNotFound />
            ) : (
                <></>
            )
    );
}

const mapStateToProps = (state) => ({
    tenant: state.tenant
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchTenant: fetchTenantDetailsAction,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FusionAuthProviderWithRedirectHandling);