import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { bindActionCreators } from 'redux';
import { fetchAdminSettings } from '../store/actions/admin';
import API from '../utils/API';
import { configurationType, tenantConfig, tenantCourseConfig, videoSetting } from './Constants';
import { Alert, Backdrop } from '@mui/material';
import Spinner from '../../../components/Common/Spinner';
import '../../../css/SettingsCss.css'

export const Settings = (props) => {
    const [settings, setSettings] = useState([])
    const [selectedVideoSettings, setselectedVideoSettings] = useState({
        id: null,
        state: null
    })
    const [backdropOpen, setBackdropOpen] = useState(false)
    const [inputValue, setInputValue] = useState(null);  
    const [alertData, setAlertData] = useState({
        severity: '',
        message: ''
    })
    const [isLoader, setLoader] = useState(false)

    const handleBackDropClose = () => {
        setBackdropOpen(false);
        setAlertData({
            severity: '',
            message: ''
        })
    };

    useEffect(() => {
        const initialValues = {};
        settings.filter(i => i.configurationtype === 1).forEach(setting => {
          initialValues[setting.id] = setting.configurationvalue || "";
        });
        setInputValue(initialValues)
      }, [settings]);
    
    const handleInputChange = (setting, e) => {
        const value = e.target.value;
        setInputValue((prevValues) => ({
          ...prevValues,
          [setting.id]: value,  
        }));
      };

    const handleVideoSetting = (settings, configurationvalue, data) => {
        setLoader(true)
        let boolValue = /^true$/i.test(data);
        console.log(settings ,data, boolValue)
        API.updateAdminSettings({
            TenantSettingsId: settings.id,
            ConfigurationValue: configurationvalue,
            IsEnabled: boolValue
        }).then(res => {
            if (res.status === 200) {
                props.fetchAdminSettingsAction()
                setLoader(false)
                setAlertData({
                    severity: 'success',
                    message: `${settings.name} settings changed successfully`
                })
                setBackdropOpen(true)
                setselectedVideoSettings({
                    id: settings.id,
                    data
                })
                
            }
            else
            {
                setBackdropOpen(true)
                setAlertData({
                    severity: 'error',
                    message: `${settings.name} settings changing failed`
                })
            }
        })
    }

    useEffect(() => {
        if (props.settingsList.length > 0) {
            var defaultChecked = props.settingsList.filter(a => a.TenantConfigurationTitle.includes(videoSetting.configuration))[0].IsEnabled
            setselectedVideoSettings(defaultChecked)
        }
    }, [props.settingsList])

    useEffect(()=>{
        props.fetchAdminSettingsAction(1)
    },[props.fetchAdminSettingsAction])

    useEffect(() => {
        setTimeout(() => {
            setBackdropOpen(false)
            setAlertData({
                severity: '',
                message: ''
            })
        }, 4000);
    }, [alertData])

    useEffect(() => {
      setSettings(props.settingsList.filter(a => Object.keys(tenantCourseConfig).includes(a.TenantConfigurationTitle)).map(set => {
        return{
            type: set.TenantConfigurationTitle,
            id: set.Id,
            state: set.IsEnabled,
            name: tenantCourseConfig[set.TenantConfigurationTitle],
            configurationvalue: set.ConfigurationValue,
            configurationtype: set.ConfigurationType
        }
      }))
    }, [props.settingsList])


    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
                onClick={handleBackDropClose}
            >
                <Alert severity={alertData.severity} style={{ position: "absolute", top: 20 }}>
                    {alertData.message}
                </Alert>
            </Backdrop>
            <div className='tenent-setting-icon'>
                {isLoader? <Spinner /> :
                <div>
            {
                settings && settings.filter(a => Object.keys(tenantCourseConfig).includes(a.type)).map((setting, idx) => {
                    const isSocialSharing = setting.type === 'SocialSharing';
                    return (
                        <div className="setting-icon-tab" key={idx}>
                        <div className="d-flex items-con">
                            <h3>{setting.name} Upload Defaults</h3>
                        </div>
                            <div className='radio-button-con'>
                                <FormControl className='w-100'>
                                    {setting.configurationtype == configurationType.input ? (
                                        <div className='tenant-input'>
                                            <input id='course-expiry-inp'
                                                label="Expiry (Days)"
                                                variant="outlined"
                                                value={inputValue[setting.id] || ""}
                                                onChange={(e) => handleInputChange(setting, e)}
                                                onKeyPress={(e) => {
                                                    if (!/[0-9]/.test(e.key)) {
                                                      e.preventDefault();
                                                    }
                                                  }}
                                                placeholder='Enter number of days to Expire'
                                                type="number"
                                            />
                                            <button
                                                className='primary-button sec-btn'
                                                onClick={() => handleVideoSetting(setting, inputValue[setting.id], true)}
                                                disabled={inputValue[setting.id] === '' || isNaN(inputValue[setting.id]) || inputValue[setting.id] == null}
                                            >
                                                Update
                                            </button>
                                        </div>
                                    ) : (
                                <RadioGroup
                                    onChange={(e) => handleVideoSetting(setting, setting.ConfigurationValue, e.target.value)}
                                    key={idx}
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={setting.state}
                                >
                                    <div className="d-flex align-items-center">
                                        <FormControlLabel value={setting.type === "SharingAllowed" ? false : true } control={<Radio />} label={ isSocialSharing ? "Enable" : "Private"}/>
                                        {setting.type === "SocialSharing" ?
                                        <p>Users can share on social media or via a shareable link.</p> :
                                        <p>(All {setting.name}s you upload will be visible only to the admin)</p>
                                         }
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <FormControlLabel value={setting.type === "SharingAllowed" ? true : false } control={<Radio />} label={isSocialSharing ? "Disable" : "Public"} />
                                        {setting.type === "SocialSharing" ?
                                        <p>Content is shared internally, with social sharing disabled.</p> :
                                        <p>(All {setting.name}s you upload will be visible to everyone.)</p>
                                        }
                                    </div>
                                </RadioGroup>
                                )}
                            </FormControl>
                        </div>
                    </div>
                    )
                })
            }
                </div>}
            </div>
          
            {/* {
                props.settingsList.map((item, idx) => {
                    return (
                     
                    )
                })
            } */}
        </>
    )
}

const mapStateToProps = (state) => ({
    settingsList: state.tenant.settings,
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchAdminSettingsAction: fetchAdminSettings
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)