import API from '../utils/API';
 
export default async function UploadVideowithsas(file, videoId, dataId, sasUrl, path, onProgress) {
  const chunkSize = 512 * 1024;
  const totalChunks = Math.ceil(file.size / chunkSize);
  const filename = `${dataId}.mp4`;
  let uploadedChunks = 0;
  for (let i = 0; i < file.size; i += chunkSize) {
    const chunk = file.slice(i, i + chunkSize);
    const fd = new FormData();
    fd.append('video', chunk, `${uploadedChunks}-${filename}`);
    fd.append('totalChunks', totalChunks);
    fd.append('fileId', dataId);
    fd.append('sasToken', sasUrl);
    fd.append('path', path);
    fd.append('videoId', videoId);
    try {
      const res = await API.uploadVideoForHls(fd);
      if (res.status === 200) {
        uploadedChunks++;
        if (onProgress) onProgress((uploadedChunks / totalChunks) * 100);
      } else {
        throw new Error(`Failed to upload chunk ${uploadedChunks + 1}`);
      }
    } catch (err) {
      console.error(`Error uploading chunk ${uploadedChunks + 1}:`, err);
      throw err;
    }
  }
 
  return { success: 200, uploadedChunks };
}