
import { connect } from "react-redux";
import * as React from "react";
import { useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserDetails } from "../../store/actions/userActions";
import store from "../../store/configureStore";
import API from "../../utils/API"
import { getProfileData } from "../../store/actions/profileDataAction";
import { fetchCategories } from "../../store/actions/CategoryAction";
import { Alert, Backdrop } from "@mui/material";
import { useTranslation } from "react-i18next";
import { languages } from "../../components/Common/Constants";
import Cookies from "js-cookie";
import i18next from "i18next";
import Checkbox from '@mui/material/Checkbox';
import ToggleButton from '@mui/material/ToggleButton';
import { useEffect } from "react";


function EditProfile(props) {
  let proPic = props.props.props.profile.ProfileImageUrl != null ? props.props.props.profile.ProfileImageUrl != "https://lmsstoragepoc.blob.core.windows.net/cdn" ? props.props.props.profile.ProfileImageUrl : sessionStorage.getItem('profilePic') : sessionStorage.getItem('profilePic');

  const inputFileRef = React.useRef();
  const userId = useSelector(() => store.getState().profileData.id);

  const { handleEditBtn } = props;
  const [displayPasswordSection] = React.useState(false);
  const [displayNotificationSection] = React.useState(false);

  const [defaultLanguage, setdefaultLanguage] = React.useState(props.props.props.profile.LanguageId);
  const [defaultFirstName, setdefaultFirstName] = React.useState(props.props.props.profile.FirstName);
  const [defaultLastName, setdefaultLastName] = React.useState(props.props.props.profile.LastName);
  const [defaultEmail, setdefaultEmail] = React.useState(props.props.props.profile.Email);

  const [language, setlanguage] = React.useState(props.props.props.profile.LanguageId);
  const [firstName, setfirstName] = React.useState("");
  const [lastName, setlastname] = React.useState("");
  const [email, setEmail] = React.useState("");

  const [deleteBtnDisable, setDeleteBtnDisable] = React.useState(false);
  const [profilepicture, setProfilePicture] = React.useState('');
  const [backdropOpen, setbackDropOpen] = React.useState(false);
  const [success, setSuccess] = React.useState({
    status: false,
    type: '',
    message: ''
  });

  const [image, setImage] = React.useState("");
  const [preview, setPreview] = React.useState(null);
  const [isLoaderOpen, setIsLoaderOpen] = React.useState(false)
  const isRightToLeft = document.body.dir === "rtl";
  const [selectedCategories, setSelectedCategories] = React.useState(props.props.props.profile.PreferredCategories || []);

  const [getPendingScore,setPendingScorePoints] = React.useState(0);

  const [imageTypeError, setImageTypeError] = React.useState();

  const { t } = useTranslation();

  const profile_Picture_Upload_Warning = t("profile_Picture_Upload_Warning")
  const profile_Picture_Uploaded = t("profile_Picture_Uploaded")
  const profile_Picture_Deleted = t("profile_Picture_Deleted")
  const profile_Updated = t("profile_Updated")
  const Upload_New = t("Upload_New")
  const Delete = t("Delete")
  const Save = t("Save")
  const Cancel = t("Cancel")
  const Firstname = t("Firstname")
  const Lastname = t("Lastname")
  const Designation = t("Designation")
  const Email = t("Email")
  const Langugage_Preference = t("Langugage_Preference")
  const General = t("General")



  const [isEngChecked, setisEngChecked] = React.useState(defaultLanguage == 0 ? true : false);
  const handleLangChange = () => { setisEngChecked(!isEngChecked); setlanguage(isEngChecked ? 1 : 0); /*alert(language)*/ };
  const handleFirstNameChange = (e) => { setfirstName(e); };
  const handleLastNameChange = (e) => { setlastname(e); };
  const handleEmailChange = (e) => { setEmail(e); };


  const handleImageChange = (e) => {

    const file = e.target.files[0];
    const fileExtension = file.name.split('.').at(-1);
    const allowedExtensions = ["jpeg", "JPEG", "JPG", "jpg", "png", "PNG"];
    if (!allowedExtensions.includes(fileExtension)) {
      setImageTypeError("Image type is not allowed.");
      return;
    }

    setImageTypeError();

    setImage(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const badgesArray = [{
    id:0,
    title: "Newbe",
    badgeScore: 101,
  },
  {
    id:1,
    title: "Explorer",
    badgeScore: 201,
  },
  {
    id:2,
    title: "Challenger",
    badgeScore: 301,
  },
  {
    id:3,
    title: "Achiever",
    badgeScore: 401,
  },
  {
    id:4,
    title: "Innovator",
    badgeScore: 501,
  },
  {
    id:5,
    title: "Strategist",
    badgeScore: 601,
  },
  {
    id:6,
    title: "Expert",
    badgeScore: 701,
  },
  {
    id:7,
    title: "Leader",
    badgeScore: 801,
  },
  {
    id:8,
    title: "Legend",
    badgeScore: 900,
  },
  {
    id:9,
    title: "complete final stage",
    badgeScore: 1000,
  },
  ];


  const handleSave = () => {
    setIsLoaderOpen(true)
    if (image) {
      API.updateProfilePicture({
        userId: userId,
        file: image
      }).then((res) => {
        props.getProfileData(props.profile.id)
        handleSuccess()
        setIsLoaderOpen(false)
      })
    }
    Cookies.set('i18next', languages[language].code)
    if (
      firstName !== defaultFirstName ||
      lastName !== defaultLastName ||
      email !== defaultEmail ||
      language !== defaultLanguage ||
      JSON.stringify(selectedCategories) !== JSON.stringify(props.props.props.profile.PreferredCategories)) {
      API.updateUserProfile({
        UserId: props.props.props.profile.id,
        Name: firstName + " " + lastName,
        FirstName: firstName,
        LastName: lastName,
        LanguageId: language,
        Email: email,
        Preferences: selectedCategories
      }).then((res) => {
        if (res.status === 201) {
          props.getProfileData(props.props.props.profile.id);
          setIsLoaderOpen(false);
          setSuccess({
            status: true,
            type: 'success',
            message: profile_Updated
          });
        } else {
          throw new Error('Profile update failed');
        }
      })
        .then(Cookies.get('i18next'))
        .finally(
          i18next.changeLanguage(Cookies.get('i18next')),
        )
    }
    else {
      setSuccess({
        status: true,
        type: 'error',
        message: "No changes"
      })
    }
  }

  React.useEffect(() => {
    props.getProfileData(props.props.props.profile.id);
  },[])

  const onBtnClick = (e) => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  };

  const handleSuccess = (data) => {
    setSuccess({
      status: true,
      type: 'success',
      message: profile_Picture_Uploaded
    })
    handleBackDropOpen(true)
  }

  React.useEffect(() => {
    props.getCategories();
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      handleBackDropClose()
    }, 4000);
  }, [backdropOpen])

  const handleBackDropOpen = (data) => {
    setbackDropOpen(data);
  };

  React.useEffect(()=>{
    if (success.status){
      setTimeout(() => {
        handleEditBtn()
      }, 2000);
    }
  }, [success])

  const handleBackDropClose = () => {
    setbackDropOpen(false);
    setSuccess({
      status: false,
      type: '',
      message: ''
    })
  };

  React.useEffect(() => {
    let btn = proPic.includes(process.env.REACT_APP_AZURE_BLOB_BASE_URL)
    setDeleteBtnDisable(btn)
  }, [proPic])

  const handleToggle = (category) => {
    setSelectedCategories(prevSelected => {
      if (prevSelected.includes(category)) {
        if (prevSelected.length <= 3) {
          return prevSelected; 
        }
        return prevSelected.filter(cat => cat !== category);
      }
      else {
          return [...prevSelected, category];
      }
    });
  };

  useEffect(() => {
    const profileData = props.props.props.profile;
    if (profileData && profileData.BadgesVM && profileData.BadgesVM.ScorePoints) {
      const myScore = profileData.BadgesVM.ScorePoints.ScoredPoints;
      const nextBadge = badgesArray.find(badge => badge.badgeScore > myScore);
      if (nextBadge) {
        const pointsToNextBadge = nextBadge.badgeScore - myScore;
        setPendingScorePoints(`${pointsToNextBadge} more point${pointsToNextBadge > 1 ? 's' : ''} to ${badgesArray[nextBadge.id + 1].title}`);
      } else {
        setPendingScorePoints('You have unlocked all badges! Congratulations!');
      }
    }
  }, [props.props.props.profile]);
  
  return (
    <div className="edit-profile">
      {isLoaderOpen && <div className="loader"><img src="assets/loading.gif" /></div>}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={success.status}
        onClick={handleBackDropClose}
      >
        <Alert severity={success.type} style={{ position: "absolute", top: 20 }}>
          {`${success.message}! `}
        </Alert>
      </Backdrop>
      <div className="user-personal-details">
        <h6>{General}</h6>
        <div className="d-flex">
          <div className="lms-flex align-items-center personal-info">
            <div className="profile-cont">
              <img src={preview ? preview : proPic} alt="profile" className="profile-image" />
              <div className="svg-con">
                <img src="assets/user-avatar.png" onClick={() => { document.getElementById('uploadinput').click() }} />
                <input id="uploadinput" style={{ display: 'none' }}
                  onChange={(e) => handleImageChange(e)}
                  onClick={(event) => {
                    event.target.value = null
                  }}
                  type="file" accept=".jpeg, .jpg, .png" />
              </div>
            {imageTypeError && <p style={{color: 'red', fontSize: '1rem', textAlign: 'center'}}>{imageTypeError}</p>}
            </div>

            <div className="userprofile lms-flex">
              <div className="profile-para lms-l-c2">
                <h1 className="profile-heading sec-pro-heading">{props.props.props.profile.Name}</h1>
                <p className="p-0 profile-mailid">{props.props.props.profile.Email}</p>
              </div>
            </div>
            <div>

            </div>
            {/* </div> */}
            {/* <div className="profile-btn-cont">
            <div className="col-4 col-md-6 d-flex justify-content-center upload-img " >
              <button className="primary-button sec-btn" onClick={() => { document.getElementById('uploadinput').click() }}>{Upload_New}</button>
              <input id="uploadinput" style={{ display: 'none' }}
                onChange={(e) => handleImageChange(e)}
                onClick={(event) => {
                  event.target.value = null
                }}
                type="file" />
            </div>

            <div className="delete-img d-flex justify-content-center">
              <button
                className="primary-button ter-btn edit-del"
                disabled={!deleteBtnDisable}
                onClick={handleDeleteProfilePicture}>{Delete}</button>
            </div>
          </div> */}
          </div>
          <div className="lms-flex align-items-center personal-info">
          <div>
              {props.props.props.profile.BadgesVM?.ScorePoints?.BadgesMaster?.Title ? (
                <img src={`assets/badges/${props.props.props.profile.BadgesVM.ScorePoints.BadgesMaster.Title}.png`} alt="Badges" className="profile-badge-img" />
                ) : (
                <img src="assets/badges/Newbe.png" alt="Default Badge" className="profile-badge-img" />
              )}
            </div>
            <div className="px-5">
            <div className="d-flex profile-badges">
                <p>{props.props.props.profile.BadgesVM?.ScorePoints?.BadgesMaster?.Title || 'No Badge'} &nbsp; | &nbsp;&nbsp; {props.props.props.profile.BadgesVM?.ScorePoints?.ScoredPoints || 0} Points</p>
              </div>
              <p className="badge-note-desc">{props.props.props.profile.BadgesVM?.ScorePoints?.ScoredPoints > 0 ? getPendingScore : "Start Earning Points"}</p>
            </div>
          </div>
        </div>
        <div className="personal-details">
          <div className="row lms-flex">
            <div className="col1">
              <label for="fname">{Firstname}</label>
              <br />
              <input type="text" id="fname" name="fname" className="m-0" placeholder={defaultFirstName} disabled onChange={(e) => handleFirstNameChange(e.target.value)} />
              <br />
            </div>
            <div className="col2">
              <label for="lname">{Lastname}</label>
              <br />
              <input type="text" id="lname" className="m-0" name="lname" placeholder={defaultLastName} disabled onChange={(e) => handleLastNameChange(e.target.value)} />
              <br />
            </div>
          </div>
          <div className="row lms-flex">
            <div className="col1">
              <label for="email">{Email}</label>
              <br />
              <input type="text" id="email" className="m-0" name="email" placeholder={defaultEmail} disabled onChange={(e) => handleEmailChange(e.target.value)} />
              <br />
            </div>
            <div className="col1">
              <label for="designation">{Designation}</label>
              <br />
              <input type="text" id="designation" name="designation" className="m-0" value={props.props.props.profile.Role} disabled />
              <br />
            </div>
          </div>

        </div>
      </div>
      {displayPasswordSection &&
        <div className="user-personal-details user-password-details">
          <h6>Password</h6>

          <div className="personal-details">
            <div className="row lms-flex">
              <div className="col1">
                <label for="oldpswd">Old Password*</label>
                <br />
                <input type="text" id="oldpswd" className="m-0" name="oldpswd" required />
                <br />
              </div>
            </div>
            <div className="row lms-flex">
              <div className="col1">
                <label for="newpswd">New Password*</label>
                <br />
                <input type="text" id="newpswd" className="m-0" name="newpswd" required />
                <br />
              </div>
            </div>
            <div className="row lms-flex">
              <div className="col1">
                <label for="reenterpswd">Re-enter New Password*</label>
                <br />
                <input
                  type="text"
                  id="reenterpswd"
                  name="reenterpswd"
                  className="m-0"
                  required
                />
                <br />
              </div>
            </div>
          </div>
        </div>
      }
      <div className="user-personal-details user-password-details preffered-cat">
        <h6>Interests</h6>
        <div className="lms-flex align-items-center mt-5 flex-wrap gap-2" >
          {props.categories.categories.map((cat) => (
            <ToggleButton
              key={cat.id}
              value="check"
              selected={selectedCategories.includes(cat.CategoryName)}
              onChange={() => handleToggle(cat.CategoryName)}
              className="category-toggle"
            >
              {cat.CategoryName}
            </ToggleButton>
          ))}
        </div>

      </div>
      <div className="user-personal-details user-password-details">
        <h6>{Langugage_Preference}</h6>

        <div className="personal-details">
          <div className=" lms-flex language-cont">
            <div className={`col-6 col-xs-12 ${isRightToLeft ? "languge-border-l" : "languge-border-r"}`}>
              <label className="checkbox-container">
                <Checkbox checked={isEngChecked} onChange={handleLangChange} value={0} />
                <img src="assets/english-us-logo.png" className="lang-img lang-img-edit" />
                <p>English (US)</p>
              </label>
            </div>
            <div className={`col-6 col-xs-12 ${isRightToLeft ? "lang-margin-r" : "lang-margin-l"}`}>
              <label className="checkbox-container">
                <Checkbox checked={!isEngChecked} onChange={handleLangChange} value={1} />
                <img src="assets/arabic-logo.png" className="lang-img lang-img-edit" />
                <p>Arabic</p>
              </label>
            </div>
          </div>
        </div>
      </div>
      {displayNotificationSection &&
        <div className="user-personal-details user-password-details">
          <h6>Alerts & Notifications</h6>

          <div className="personal-details">
            <div className="row lms-flex">
              <div className="col3">
                <label className="checkbox-container">
                  <input type="checkbox" />

                  <p>New Course or Video added</p>
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="row lms-flex">
              <div className="col4">
                <label className="checkbox-container">
                  <input type="checkbox" />

                  <p>Account Activity</p>
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="row lms-flex">
              <div className="col4">
                <label className="checkbox-container">
                  <input type="checkbox" />

                  <p>Shared videos by others</p>
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <h6 className="email-notification">Email Notifications</h6>

            <div className="row lms-flex">
              <div className="col3">
                <label className="checkbox-container">
                  <input type="checkbox" />

                  <p>New Course or Video added</p>
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="row lms-flex">
              <div className="col4">
                <label className="checkbox-container">
                  <input type="checkbox" />

                  <p>Account Activity</p>
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="row lms-flex">
              <div className="col4">
                <label className="checkbox-container">
                  <input type="checkbox" />

                  <p>Shared videos by others</p>
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>

        </div>
      }
      <div className="action-items">
        <div className="col-xl-2"><button className="primary-button ter-btn" onClick={handleEditBtn}>{Cancel}</button></div>
        <div className="col-xl-2 col-md-3 d-flex justify-content-end save-btn-wd"><button className="primary-button sec-btn save-btn" onClick={handleSave}>{Save}</button></div>
      </div>

    </div>
  );
}


const mapStateToProps = (state, ownProps) => {
  return {
    profile: state.profileData,
    categories: state.category,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProfileData: getProfileData,
      getCategories: fetchCategories,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
