import React, { Component } from "react";
import { connect } from "react-redux";
import PlaceholderLoader from "../../components/Common/PlaceholderLoader";
import { bindActionCreators } from "redux";
import { fetchSharedByMeVideos, fetchSharedBymeVideosLoadMore } from "../../store/actions/tutorials";
import { fetchSharedByMeCourses, fetchSharedByMeCourseLoadMore } from "../../store/actions/courses";
import BrowseHeader from "./BrowseHeader";
import BrowseBody from "./BrowseBody";
import API from "../../utils/API";
import LoadingScreen from "../../components/Common/LoadingScreen";
import { BlueBarTranslated } from "../../components/Common/BlueBar";

export class BrowseSharedByYou extends Component {
  constructor(props) {
    super(props);
    this.timeout = 0;
    this.state = {
      videoHasMore: false,
      courseHasMore: true,
      filterVideoData: {
          Count: 0,
          Videos: []
        },
      filterCourseData: {
          Count: 0,
          Playlist: []
        },
      filterField: "",
      sortField: "",
      search: "",
    }
  }

  async componentDidMount() {
    const { fetchSharedByMeCourses, fetchSharedByMeVideos } = this.props;
    const videoPayload = { PageNumber: this.props.sharedByMeVideoPageNumber - 1 };
    const coursePayload = { PageNumber: this.props.sharedByMeCoursePageNumber - 1 };
    fetchSharedByMeCourses(coursePayload);
    fetchSharedByMeVideos(videoPayload);
  }

  filterData = (evt) => {
    this.setState({ search: evt.trim() })
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      // console.log(this.state.search)
      API.getSharedByMeCourses({
        filterField: 'Title',
        filterText: evt
      }).then(res => res.status === 200 ? this.setState({ filterCourseData: {
          Count: res.data.count,
          Playlist: res.data.Playlist
        } }) : this.setState({ filterCourseData: {
          Count: 0,
          Playlist: []
        } }))
      API.getSharedByMeVideos({
        filterField: 'Title',
        filterText: evt
      }).then(res => res.status === 200 ? this.setState({ filterVideoData: {
          Count: res.data.count,
          Videos: res.data.Videos
        } }) : this.setState({ filterVideoData: {
          Count: 0,
          Videos: []
        } }))
    }, 500);
  }

  changeSort = (evt) => {
    this.setState({ search: evt.trim() })
    this.setState({
      sortField: evt
    })
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      // console.log(this.state.search)
      API.getSharedByMeCourses({
        sortField: 'CreatedDate',
        sortType: evt,
        FilterField: 'CategoryName',
        FilterText: this.state.filterField,
      }).then(res => res.status === 200 ? this.setState({ filterCourseData: {
          Count: res.data.count,
          Playlist: res.data.Playlist
        } }) : this.setState({ filterCourseData: {
          Count: 0,
          Playlist: []
        } }))
      API.getSharedByMeVideos({
        sortField: 'CreatedDate',
        sortType: evt,
        FilterField: 'CategoryName',
        FilterText: this.state.filterField,
      }).then(res => res.status === 200 ? this.setState({ filterVideoData: {
          Count: res.data.count,
          Videos: res.data.Videos
        } }) : this.setState({ filterVideoData: {
          Count: 0,
          Videos: []
        } }))
    }, 500);
  }

  change = (evt) => {
    this.setState({ search: evt.trim() })
    this.setState({
      filterField: evt
    })
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      // console.log(this.state.search)
      API.getSharedByMeCourses({
        filterField: 'CategoryName',
        filterText: evt,
        SortField: 'CreatedDate',
        SortType: this.state.sortField,
      }).then(res => res.status === 200 ? this.setState({ filterCourseData: {
          Count: res.data.count,
          Playlist: res.data.Playlist
        } }) : this.setState({ filterCourseData: {
          Count: 0,
          Playlist: []
        } }))
      API.getSharedByMeVideos({
        filterField: 'CategoryName',
        filterText: evt,
        SortField: 'CreatedDate',
        SortType: this.state.sortField,
      }).then(res => res.status === 200 ? this.setState({ filterVideoData: {
          Count: res.data.count,
          Videos: res.data.Videos
        } }) : this.setState({ filterVideoData: {
          Count: 0,
          Videos: []
        } }))
    }, 500);
  }

  handleBrowseVideoScroll = (event) => {
    const { fetchSharedBymeVideosLoadMore, videoLazyloader, sharedByMeVideoPageNumber } = this.props;
    const payload = {
      PageNumber: sharedByMeVideoPageNumber
    };
    if (videoLazyloader === false) {
      this.setState({
        videoHasMore: false
      })
    }
    setTimeout(() => {
      fetchSharedBymeVideosLoadMore(payload);
    }, 1000);
  }

  handleBrowseCourseScroll = (event) => {
    const { fetchSharedByMeCourseLoadMore, courseLazyLoader, sharedByMeCoursePageNumber } = this.props;
    const payload = {
      PageNumber: sharedByMeCoursePageNumber
    };
    console.log(courseLazyLoader)

    if (courseLazyLoader === false) {
      this.setState({
        courseHasMore: false
      })
    }
    setTimeout(() => {
      fetchSharedByMeCourseLoadMore(payload);
    }, 1000);
  }

  render() {
    const { loaded, sharedByMeVideos, sharedByMeCourses, videoLazyloader, courseLazyLoader } = this.props;
    return loaded ? (
      <div>
        <BlueBarTranslated props = {this.props}/>
        <BrowseHeader props={this.props} browseType={"SharedByMe"} filterData={(evt) => this.filterData(evt)} changeSort={(evt) => this.changeSort(evt)} change={(evt) => this.change(evt)}></BrowseHeader>
        <BrowseBody
          props={this.props}
          tutorials={sharedByMeVideos}
          courses={sharedByMeCourses}
          loaded={loaded}
          searchValue={this.state.search}
          filterDataVideo={this.state.filterVideoData}
          filterCourseData={this.state.filterCourseData}
          handleBrowseVideoScroll={this.handleBrowseVideoScroll}
          handleBrowseCourseScroll={this.handleBrowseCourseScroll}
          videoLazyloader={videoLazyloader}  
          courseLazyLoader={courseLazyLoader}
          videoHasMore = {this.state.videoHasMore}
          courseHasMore = {this.state.courseHasMore}
          type="sharebyyou"
        ></BrowseBody>
      </div>
    ) : (
      <LoadingScreen></LoadingScreen>
    );
  }
}

const mapStateToProps = (state, props) => ({
  loaded: state.tutorials.loaded,
  videoLazyloader: state.tutorials.sharedBymeVideoLazyLoader,
  courseLazyLoader: state.courses.sharedBymeCourseLazyLoader,
  profile: state.user,
  sharedByMeCourses: state.courses.sharedbymecourses,
  sharedByMeVideos: state.tutorials.sharedbymevideos,
  sharedByMeVideoPageNumber: state.tutorials.sharedByMeVideoPageNumber,
  sharedByMeCoursePageNumber: state.courses.sharedByMeCoursePageNumber
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchSharedByMeVideos: fetchSharedByMeVideos,
      fetchSharedByMeCourses: fetchSharedByMeCourses,
      fetchSharedBymeVideosLoadMore: fetchSharedBymeVideosLoadMore,
      fetchSharedByMeCourseLoadMore: fetchSharedByMeCourseLoadMore,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BrowseSharedByYou);
