import * as React from "react";
import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { connect } from "react-redux";
import moment from "moment";
import API from "../utils/API";
import { default as UserAPI } from "../../../utils/API";
import { Alert, Backdrop, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from "@mui/material";
import { getAllUsers, loadMoreUsers } from "../../../store/actions/AllUsersAction";
import TableLayout from "../Common/UserTableLayout";
import { bindActionCreators } from "redux";
import { fetchQuestions, fetchQuestionsLoadMore, fetchQuiz, fetchQuizLoadmore } from "../store/actions/admin";
import QuizTableLayout from "../Common/QuizTableLayout";
import Spinner from "../../../components/Common/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import ErrorTab from "../../../components/Common/ErrorTab";
import { questionCollectionPath, quizCollectionPath } from "../Common/Constants";
import QuestionTableLayout from "../Common/QuestionTableLayout";


function QuizDatagriddata({ quiz, quizPageNumber, lazyloader, quizloaded, question, questionPageNumber, questionlazyloader, alertCheck, fetchQuizLoadMoreAction, fetchQuestionsLoadMoreAction, fetchQuestionsAction, searchValue, fetchQuizAction, edit, filterData, quizCount, questionCount,questionSearchLoader }) {
    const [success, setSuccess] = React.useState(false)
    const [alert, setAlert] = React.useState({
        active: false,
        title: '',
        message: ''
    });
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [deleteData, setDeleteData] = React.useState({});
    const [hasMore, setHasMore] = React.useState(true);
    const [questionHasMore, setQuestionHasMore] = React.useState(true);


    const { t } = useTranslation()

    const Are_You_Sure = t("Are_You_Sure")
    const Cancel = t("Cancel")
    const Delete = t("Delete")
    const You_want_to_delete = t("You_want_to_delete")
    const quiz_on = t("Quiz_On")
    const ques_mark = t("?")


    const handleClose = () => {
        setDialogOpen(false);
        setDeleteData({})
    }

    const loadMore = () => {
        if (lazyloader === false) {
            setHasMore(false)
        }
        setTimeout(() => {
            fetchQuizLoadMoreAction({ PageNumber: quizPageNumber, PageSize: 20 })
        }, 1000);
    }

    const questionLoadMore = () => {
        if (questionlazyloader === false) {
            setQuestionHasMore(false)
        }
        setTimeout(() => {
            fetchQuestionsLoadMoreAction({
                Pagination: {
                    PageNumber: questionPageNumber,
                    PageSize: 20
                }
            })
        }, 1000);
    }

    const handleBackDropClose = () => {
        setAlert({
            active: false,
            title: '',
            message: ''
        })
    }

    const handleDelete = async () => {
        if (window.location.pathname.split('/').pop() === quizCollectionPath) {
            API.removeQuiz(deleteData.Id).then(res => res.status === 201 &&
                setAlert({ active: true, user: deleteData.title, message: `Quiz on ${deleteData.title} Deleted!` }),
                handleClose()
            ).then(async (res) =>
                await fetchQuizAction({
                    PageSize: 20
                }),
            )
        }
        if (window.location.pathname.split('/').pop() === questionCollectionPath) {
            API.removeQuestions(deleteData.Id).then(res => res.status === 200 &&
                setAlert({ active: true, user: deleteData.title, message: `Question ${deleteData.title} Deleted!` }),
            ).then(async (res) => {
                await API.removeQuestionfromAllQuiz(deleteData.Id),
                    await fetchQuestionsAction({
                        Pagination: {
                            PageNumber: 1,
                            PageSize: 20
                        }
                    })
                handleClose()
            })
        }
    }
    React.useEffect(() => {
        if (success) {
            setTimeout(async () => {
                await fetchQuizAction({
                    quizPageNumber: quizPageNumber,
                    PageSize: 20
                })
            }, 4000);
        }
    }, [success]);

    React.useEffect(() => {
        setTimeout(() => {
            handleBackDropClose();
        }, 4000);
    }, [alert.active]);

    React.useEffect(() => {
        setTimeout(() => {
            setSuccess(false)
        }, 3000);
    }, [success])



    const handleDeleteDialog = (data, isTrue) => {
        if (window.location.pathname.split('/').pop() === quizCollectionPath) {
            {
                var model = {
                    content: `${You_want_to_delete} quiz ${data.Title} ${ques_mark}`,
                    Id: data.Id,
                    title: data.Title
                }
                setDeleteData(model)
                setDialogOpen(isTrue)
            }
            if (window.location.pathname.split('/').pop() === questionCollectionPath) {
                {
                    var model = {
                        content: `${You_want_to_delete} question ${data.Title} ${ques_mark}`,
                        Id: data.Id,
                        title: data.Title
                    }
                }
                setDeleteData(model)
                setDialogOpen(isTrue)
            }

        }
    }

    const quizColumns = [
        // { field: "id", headerName: "ID", width: 50 },

        {
            field: "QuizTitle",
            headerName: "Quiz Title",
            sortable: false,
            width: 400,
            align: document.body.dir === 'ltr' ? "left" : "right",

        },
        {
            field: "TotalQuestions", headerName: "Total Questions", width: 150, sortable: false, align: 'center',
        },
        {
            field: "TotalMarks", headerName: "Total Marks", width: 100, sortable: false, align: 'center',
        },
        {
            field: "PassMarks", headerName: "Pass Marks(%)", width: 100, sortable: false, align: 'center',
        },
        {
            field: "DifficultyRatio", headerName: "Difficulty Ratio", width: 150, sortable: false, align: 'center',
        },
        {
            field: "CreatedOn", headerName: "Created On", width: 150, sortable: false, align: 'center'
        },
        {
            field: "Delete",
            headerName: "Delete",
            sortable: false,
            width: 100,
            align: 'center',
        },
    ];
    const questionColumns = [
        // { field: "id", headerName: "ID", width: 50 },

        {
            field: "QuestionTitle",
            headerName: "Question Title",
            sortable: false,
            width: 400,
            align: document.body.dir === 'ltr' ? "left" : "right",

        },
        {
            field: "Tags", headerName: "Tags", width: 150, sortable: false, align: 'center',
        },
        {
            field: "QuestionType", headerName: "Question Type", width: 100, sortable: false, align: 'center',
        },
        {
            field: "DifficultyLevel", headerName: "Difficulty Level", width: 100, sortable: false, align: 'center',
        },
        // {
        //     field: "PointsforCorrectAnswer", headerName: "Points for Correct Answer", width: 150, sortable: false, align: 'center',
        // },
        {
            field: "CreatedOn", headerName: "Created On", width: 150, sortable: false, align: 'center'
        },
        {
            field: "Edit",
            headerName: "Edit",
            sortable: false,
            width: 100,
            align: 'center',
        },
        {
            field: "Delete",
            headerName: "Delete",
            sortable: false,
            width: 100,
            align: 'center',
        },
    ];

    useEffect(() => {
        if (alertCheck.active) {
            setAlert(alertCheck)
        }
        else {
            setAlert({
                active: false,
                title: '',
                message: ''
            })
        }
    }, [alertCheck])

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={alert.active}
                onClick={handleBackDropClose}
            >
                <Alert severity="success" style={{ position: "absolute", top: 20 }}>
                    {`${alert.message}`}
                </Alert>
            </Backdrop>
            <Dialog open={dialogOpen} onClose={handleClose} className="delete-confirmation">
                <DialogTitle>{Are_You_Sure}</DialogTitle>
                <Divider className="confirmation-popup-divider" />
                <DialogContent>
                    <DialogContentText>
                        {deleteData.content}
                    </DialogContentText>
                </DialogContent>
                <Divider className="confirmation-popup-divider" />
                <DialogActions>
                <button
                        className="primary-button ter-btn ad-share-btn red-button"
                        style={{ backgroundColor: '#043677', color: 'white' }}
                        // disabled={selectedUser.length > 0 ? false : true}
                        onClick={handleDelete}
                    >
                        {Delete}
                    </button>
                    <button
                        className="primary-button ter-btn"
                        onClick={handleClose}
                    >
                        {Cancel}
                    </button>
                </DialogActions>
            </Dialog>
            {window.location.pathname.split('/').pop() === quizCollectionPath &&
                <InfiniteScroll
                    style={{ overflow: 'hidden' }}
                    dataLength={searchValue.length > 0 || filterData.Count > 0 ? filterData.Count : quiz.length}
                    next={loadMore}
                    hasMore={hasMore}
                    loader={quizCount > quiz.length && <Spinner />}
                    endMessage
                >
                    <QuizTableLayout columns={quizColumns} data={searchValue.length > 0 || filterData.Count > 0 ? filterData.Data : quiz} delete={handleDeleteDialog} edit={edit} />
                </InfiniteScroll>
            }

            {window.location.pathname.split('/').pop() === questionCollectionPath &&
                <InfiniteScroll
                    style={{ overflow: 'hidden' }}
                    dataLength={searchValue.length > 0 || filterData.Count > 0 ? filterData.Count : question.length}
                    next={questionLoadMore}
                    hasMore={questionHasMore}
                    loader={questionCount > question.length && <Spinner />}
                    endMessage
                >
                    <QuestionTableLayout columns={questionColumns} questionSearchLoader= {questionSearchLoader} data={searchValue.length > 0 || filterData.Count > 0 ? filterData.Data : question} delete={handleDeleteDialog} edit={edit} />
                </InfiniteScroll>
            }
        </>
    );
}

const mapStateToProps = (state) => (
    {
        quiz: state.quiz.Quiz.Quiz,
        quizCount: state.quiz.Quiz.Count,
        quizPageNumber: state.quiz.QuizPagenumber,
        lazyloader: state.quiz.quizLazyLoader,
        quizloaded: state.quiz.loaded,
        question: state.questions.Questions.Questions,
        questionCount: state.questions.Questions.Count,
        questionPageNumber: state.questions.QuestionPageNumber,
        questionlazyloader: state.questions.questionLazyLoader,
        questionsloaded: state.questions.loaded,
    })

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchQuizLoadMoreAction: fetchQuizLoadmore,
        fetchQuizAction: fetchQuiz,
        fetchQuestionsLoadMoreAction: fetchQuestionsLoadMore,
        fetchQuestionsAction: fetchQuestions,
    }, dispatch)
};
export default connect(mapStateToProps, mapDispatchToProps)(QuizDatagriddata);
